import firebase from 'firebase/app';

// Add products to use
import 'firebase/auth';
import 'firebase/firestore';

const developConfig = {
  apiKey: 'AIzaSyAVzRfQXWKG1j6_QQlLL-4e2lxssC8W1G0',
  authDomain: 'codeforces-anytime-dev.firebaseapp.com',
  databaseURL: 'https://codeforces-anytime-dev.firebaseio.com',
  projectId: 'codeforces-anytime-dev',
  storageBucket: 'codeforces-anytime-dev.appspot.com',
  messagingSenderId: '1057957295716',
  appId: '1:1057957295716:web:256e0b290a716e739d5171',
};

const productionConfig = {
  apiKey: 'AIzaSyDfzqjU_3paywbtQEsuH7-4pk4K_kFYpxE',
  authDomain: 'codeforces-anytime.firebaseapp.com',
  databaseURL: 'https://codeforces-anytime.firebaseio.com',
  projectId: 'codeforces-anytime',
  storageBucket: 'codeforces-anytime.appspot.com',
  messagingSenderId: '148350114471',
  appId: '1:148350114471:web:9b20705b47c288cd',
};

const firebaseConfig =
  process.env.REACT_APP_ENV === 'production' ? productionConfig : developConfig;

firebase.initializeApp(firebaseConfig);

export default firebase;
